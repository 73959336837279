import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import close from "../../assets/Product/Close.svg";
import shade from "../../assets/Product/Shade1.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import api from "../../api/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Logo from "../../assets/Logo/ShillsIcon.png";
import { createPromiseToast } from "../../utils/createPromiseToast";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { clearModalState, toggleModal } from "../../store/productModal.slice";
import { useNavigate } from "react-router-dom";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

const SelectVarientModal = () => {
  const {
    isOpen: open,
    productCode: prd_code,
    sale_rate,
  } = useSelector((state) => state.productModalState);

  const { isAuthenticated } = useSelector((state) => state.auth);

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [productVariantData, setPRoductVariantData] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClose = () => {
    setSelectedVariant([]);
    dispatch(clearModalState());
    dispatch(toggleModal());
  };
  const [quantity, setQuantity] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState([]);
  const queryClient = useQueryClient();

  const {
    data: productDetails,
    isLoading: isProductVariantLoading,
    refetch: productDetailsRefetch,
  } = useQuery({
    queryKey: ["product-variant", prd_code, page, rows],
    queryFn: () => api.getProductVariants(prd_code, page, rows),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: prd_code !== null,
  });

  const { mutate: addToCartMutate, isPending: addToCartLoading } = useMutation({
    mutationFn: async (data) => await api.addToCart(data),
    onSuccess: (res) => {
      if (res.data[0].result === 1) {
        queryClient.invalidateQueries({ queryKey: ["cart"] });
        queryClient.invalidateQueries({ queryKey: ["cartSummary"] });
        setQuantity(1);
        createPromiseToast({
          success: true,
          message: "Product added to the cart successfully",
        });
      } else {
        createPromiseToast({ success: false, message: res.data[0].message });
      }
    },
    onError: (error) => {
      console.error(error);
      createPromiseToast({ success: false, message: error.message });
    },
  });

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncreaseQuantity = () => {
    if (quantity > 0) {
      setQuantity(quantity + 1);
    }
  };

  const handleAddToCart = () => {
    const values = {
      prd_code: selectedVariant?.PRD_CODE,
      quantity,
      rate: sale_rate,
    };
    if (!isAuthenticated) {
      createPromiseToast({
        success: false,
        message: "Please login to add product in cart.",
      });
      navigate("/login");
      handleClose();
      return;
    }
    if (selectedVariant.length !== 0) {
      const cartData = queryClient.getQueryData(["cart"]);
      const isExist = cartData?.find(
        (item) => item?.PRD_CODE === values?.prd_code
      );
      if (isExist) {
        createPromiseToast({
          success: false,
          message: "Product already in the cart",
        });
        return;
      }
      addToCartMutate(values);
    } else {
      createPromiseToast({
        success: false,
        message: "Please select a variant",
      });
    }
    setSelectedVariant([]);
    // handleClose();
  };
  useEffect(() => {
    // console.log(productDetails?.data);
    setPRoductVariantData(productDetails?.data);
  }, [productDetails]);

  useEffect(() => {
    // add just to not use the previous values
    setPage(1);
    setRows(10);
  }, [prd_code]);

  if (prd_code == null) {
    return;
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
      }}
    >
      <Box sx={style}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          paddingTop={{ xs: ".8rem", md: "1rem" }}
          paddingBottom={{ xs: ".5rem", md: ".8rem" }}
          width={"100%"}
          mb={1}
        >
          <Typography
            sx={{
              fontSize: { xs: "1rem", md: "1.25rem" },
              fontWeight: "400",
            }}
          >
            More Colours
          </Typography>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={handleClose}
            height={{ xs: 35, sm: 40, md: 50 }}
          >
            <img src={close} alt="" height={"100%"} />
          </Box>
        </Box>
        <Divider />

        {/* product container  */}

        <Box
          sx={{
            flex: 1,
            paddingY: { xs: ".5rem", md: "1rem" },
            minHeight: { xs: "11rem", md: "22rem" },
          }}
        >
          <Grid container sx={{}}>
            {!isProductVariantLoading ? (
              productVariantData?.map((dt) => (
                <Grid
                  item
                  xs={2.4}
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  onClick={() => setSelectedVariant(dt)}
                  borderRadius={0.5}
                  sx={{
                    cursor: "pointer",
                    marginY: ".5rem",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: { xs: "4rem", md: "7rem" },
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src={dt.VIMAGE}
                      alt="variants"
                      height={"100%"}
                      width={"100%"}
                      style={{
                        border:
                          selectedVariant?.PRD_CODE === dt?.PRD_CODE
                            ? "2px solid #FF6C86"
                            : "none",
                        borderRadius: ".4rem",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: ".6rem",
                        md: ".8rem",
                      },
                      marginTop: ".5rem",
                    }}
                  >
                    {dt?.PRD_CODE}
                  </Typography>
                </Grid>
              ))
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: { xs: "11rem", md: "18rem" },
                }}
              >
                <CircularProgress size={24} sx={{ color: "#FF6C86" }} />
              </Box>
            )}
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column-reverse", md: "row" },
            width: "100%",
            justifyContent: "space-between",
            paddingY: { xs: ".8rem", md: "1rem" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: { xs: ".5rem", md: "2rem" },
              marginTop: { xs: ".5rem", md: 0 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                placeItems: "center",
                width: { md: "50%" },
              }}
            >
              <Button
                onClick={handleDecreaseQuantity}
                sx={{
                  color: "black",
                  backgroundColor: "#F2F4F9",
                  "&:hover": {
                    backgroundColor: "#FF6C86",
                  },
                  borderRadius: 3,
                }}
              >
                <RemoveIcon />
              </Button>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "3rem",
                  aspectRatio: 1,
                  backgroundColor: "#fff",
                }}
              >
                {quantity}
              </Typography>
              <Button
                onClick={handleIncreaseQuantity}
                sx={{
                  color: "black",
                  backgroundColor: "#F2F4F9",
                  "&:hover": {
                    backgroundColor: "#FF6C86",
                  },
                  borderRadius: 3,
                }}
              >
                <AddIcon />
              </Button>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                onClick={handleAddToCart}
                variant="contained"
                sx={{
                  width: { xs: 35, md: "7rem" },
                  height: { md: 40, xs: 35 },
                  bgcolor: "#FF6C86",
                  borderRadius: "25px",
                  color: "#fff",
                  fontWeight: 400,
                  fontSize: { xs: ".6rem", md: "0.8rem" },
                  textTransform: "capitalize",
                  ":hover": {
                    bgcolor: "#FF6C86",
                  },
                }}
              >
                {addToCartLoading ? (
                  <CircularProgress size={20} sx={{ color: "#FFFFFF" }} />
                ) : (
                  <>
                    <Typography
                      sx={{
                        display: { xs: "none", md: "flex" },
                        fontSize: {
                          xs: ".6rem",
                          md: ".8rem",
                        },
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Add To Cart
                    </Typography>
                    <AddShoppingCartIcon
                      sx={{
                        display: {
                          md: "none",
                        },
                        fontSize: { xs: "1.2rem" },
                      }}
                    />
                  </>
                )}
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              gap={{ xs: 0.5, md: 2 }}
            >
              <Button
                onClick={() => setPage(page - 1)}
                disabled={page === 1}
                sx={{
                  borderRadius: { xs: 20, md: 25 },
                  width: { xs: 40, md: 50 },
                  height: { xs: 40, md: 50 },
                  backgroundColor: "#F2F4F9",
                  "&:disabled": {
                    color: "#9AA0A1",
                  },
                  "&:hover": {
                    backgroundColor: "#FFE9ED",
                  },
                }}
              >
                <ChevronLeftIcon />
              </Button>
              <Button
                onClick={() => setPage(page + 1)}
                disabled={
                  productVariantData &&
                  page === Math.ceil(productVariantData[0]?.TOTROWS / rows)
                }
                sx={{
                  borderRadius: { xs: 20, md: 25 },
                  backgroundColor: "#F2F4F9",
                  width: { xs: 40, md: 50 },
                  height: { xs: 40, md: 50 },
                  "&:disabled": {
                    color: "#9AA0A1",
                  },
                  "&:hover": {
                    backgroundColor: "#FFE9ED",
                  },
                }}
              >
                <ChevronRightIcon />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SelectVarientModal;

const style = {
  width: { xs: "90%", md: "50%" },
  paddingX: { md: "2rem", xs: "1rem" },
  height: { md: "auto" },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};
