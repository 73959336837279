import React, { useLayoutEffect } from "react";
import "./App.css";
import "react-lazy-load-image-component/src/effects/blur.css";

import { Suspense } from "react";
import {
  Routes,
  Route,
  useLocation,
  Outlet,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { ProtectedRouteList, UnProtectedRouteList } from "./routes/routes";
import OutletComponent from "./components/OutletComponent";
import { useDispatch, useSelector } from "react-redux";
import PageNotFound from "./components/PageNotFound";
import { ThemeProvider } from "@mui/material/styles";
import muiTheme from "./theme/theme";
import api from "./api/api";
import { removeAuthHeaders, setAuthHeaders } from "./api/instance";
import { createPromiseToast } from "./utils/createPromiseToast";
import { logOut } from "./store/auth.slice";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    handleInitialLoad();
  }, []);

  const handleInitialLoad = async () => {
    try {
      const isAuth = localStorage.getItem("isAuthenticated");
      const mobNo = localStorage.getItem("mobNo");
      const authToken = localStorage.getItem("authToken");
      console.log(isAuth, mobNo, authToken, "initial load");

      if (!isAuth || !mobNo || !authToken) {
        return;
      }
      setAuthHeaders(authToken, mobNo);
      const userData = await api.profile();

      if (userData?.data[0]?.result == 0) {
        dispatch(logOut());
        navigate("/");
        return;
      }

      const userType = userData?.data[0]?.USERTYPE;
      const status = userData?.data[0]?.STATUS;

      if (status && status !== "1") {
        dispatch(logOut());
        navigate("/");
        return createPromiseToast({
          success: false,
          message: "Your account is suspended",
        });
      }

      if (userType && userType !== "0") {
        dispatch(logOut());
        navigate("/");
        return createPromiseToast({
          success: false,
          message: "Please Use Our Mobile App",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <ThemeProvider theme={muiTheme}>
        <AuthRoutes />
        {ProtectedRouteList.find(
          (dt) =>
            dt[1].paths[0].split("/")[1] === location.pathname.split("/")[1]
        ) ? null : (
          <UnAuthRoutes />
        )}
      </ThemeProvider>
    </div>
  );
}

const UnAuthRoutes = ({ auth }) => {
  return (
    <Routes>
      <Route element={<OutletComponent />}>
        {UnProtectedRouteList.map((_route) => {
          const route = _route[1];
          const { key, paths, element, suspense, fallback } = route;
          return paths.map((_path) => {
            return (
              <Route
                key={key}
                path={_path}
                exact
                element={
                  suspense ? (
                    <Suspense {...{ fallback }}>{element}</Suspense>
                  ) : (
                    element
                  )
                }
              />
            );
          });
        })}
        <Route path="*" Component={PageNotFound} />
      </Route>
    </Routes>
  );
};

const AuthRoutes = ({ auth }) => {
  let location = useLocation();
  return (
    <Routes>
      <Route element={<OutletComponent />}>
        {ProtectedRouteList.map((_route) => {
          const route = _route[1];
          const { key, paths, element, suspense, fallback } = route;
          return paths.map((_path) => {
            return (
              <Route element={<ProtectedRoute />}>
                <Route
                  key={key}
                  path={_path}
                  exact
                  element={
                    suspense ? (
                      <Suspense {...{ fallback }}>{element}</Suspense>
                    ) : (
                      element
                    )
                  }
                />
              </Route>
            );
          });
        })}
      </Route>
    </Routes>
  );
};

const ProtectedRoute = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  let location = useLocation();
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={"/login"} state={{ from: location }} replace />
  );
};

export default App;
