import { createSlice } from "@reduxjs/toolkit";
import { setAuthHeaders } from "../api/instance";

const initialState = {
  categories:null,
};

export const slice = createSlice({
  name: "Category",
  initialState,
  reducers: {
    setCategories: (state, action) => {
        state.categories = action.payload.categories;
    },
  },
});

export const { setCategories } = slice.actions;

export default slice.reducer;
