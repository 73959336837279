import { createSlice } from "@reduxjs/toolkit";
import { setAuthHeaders } from "../api/instance";

const initialState = {
  products: [],
  cartSummary: null,
};

export const slice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartProducts: (state, action) => {
      state.products = action.payload;
    },
    setCartSummary: (state, action) => {
      state.cartSummary = action.payload;
    },
  },
});

export const { setCartProducts, setCartSummary } = slice.actions;

export default slice.reducer;
