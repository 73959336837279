import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ShoppingCartDrawerCard from "./Cards/ShoppingCartDrawerCard";
import routes from "../routes/routes";
import { useNavigate } from "react-router-dom";
import close from "../assets/Product/Close.svg";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../api/api";
import { useDispatch, useSelector } from "react-redux";
import { setCartProducts, setCartSummary } from "../store/cart.slice";
import deleteIcon from "../assets/Cart/deleteIcon.svg";
import { createPromiseToast } from "../utils/createPromiseToast";

const ShoppingDrawer = ({
  container,
  shoppingCartOpen,
  handleShoppingDrawerToggle,
}) => {
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const disptach = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);

  const queryClient = useQueryClient();
  const { mutate: editCartMutate, isPending: editCartPending } = useMutation({
    mutationFn: async ({ billno, data }) => await api.editCart(billno, data),
    onSuccess: ({ data }) => {
      if (data[0]?.result === 1) {
        queryClient.invalidateQueries({ queryKey: ["cart"] });
        queryClient.invalidateQueries({ queryKey: ["cartSummary"] });
      }
    },
    onError: (error) => console.error(error),
  });

  const { data: cartData, isLoading } = useQuery({
    queryKey: ["cart"],
    queryFn: () =>
      api.getCart().then((res) => {
        disptach(setCartProducts(res.data));
        return res.data;
      }),
    staleTime: " Infinity",
    cacheTime: " Infinity",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated,
  });

  const { data: cartSummary, isLoading: summaryLoading } = useQuery({
    queryKey: ["cartSummary"],
    queryFn: () =>
      api.getCartSummary().then((res) => {
        disptach(setCartSummary(res.data[0]));
        return res.data[0];
      }),
    staleTime: " Infinity",
    cacheTime: " Infinity",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated,
  });

  const { mutate: clearCartMutate, isPending: isClearCartPending } =
    useMutation({
      mutationFn: () => api.clearCart(),
      onSuccess: ({ data }) => {
        if (data[0]?.result === 1) {
          createPromiseToast({
            success: true,
            message: "Cart Cleared Successfully",
          });
          queryClient.invalidateQueries({ queryKey: ["cart"] });
          queryClient.invalidateQueries({ queryKey: ["cartSummary"] });
        }
      },
      onError: (error) => console.error(error),
    });

  useEffect(() => {
    setTotal(cartSummary?.AMOUNT);
  }, [cartSummary]);

  // console.log(cartData);

  const shoppingDrawer = (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{ textAlign: "center", height: "100%" }}
      py={2}
    >
      <Box display={"flex"} justifyContent={"space-between"} p={2}>
        <Typography sx={{ fontSize: { xs: "1.2rem" } }}>
          Shopping Cart
        </Typography>
        <Box onClick={handleShoppingDrawerToggle}>
          <img src={close} width={30} alt="" style={{ cursor: "pointer" }} />
        </Box>
      </Box>
      <Divider />
      {cartSummary?.CNT !== "0" && (
        <Box
          sx={{
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          padding={2}
        >
          <Button
            type="button"
            variant="text"
            sx={{ boxShadow: 2, px: 1.5, py: 1 }}
            onClick={clearCartMutate}
          >
            <img
              src={deleteIcon}
              alt="delete"
              srcset=""
              width={20}
              height={20}
            />
            <Typography sx={{ fontSize: 12, marginLeft: 1.2 }}>
              Clear cart
            </Typography>
          </Button>
        </Box>
      )}

      <Box maxHeight={"60%"} overflow={"auto"}>
        {cartData && cartData[0]?.result === 0 && (
          <Alert severity="info" sx={{ mt: 4, mx: 2 }}>
            No product added to cart.
          </Alert>
        )}
        {cartData &&
          cartData[0]?.result !== 0 &&
          cartData?.map((item, id) => (
            <Box my={2} px={3} key={id}>
              <ShoppingCartDrawerCard
                data={item}
                editCartMutate={editCartMutate}
              />
            </Box>
          ))}
      </Box>
      <Divider sx={{ my: 2 }} />

      <Box sx={{ marginTop: "auto" }}>
        {cartSummary && cartSummary?.CNT !== "0" && (
          <>
            <Box
              px={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              mt={2}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  color: "#242424",
                  // marginY: 3,
                  fontSize: { xs: "1rem", md: "1.2rem" },
                }}
              >
                Subtotal:
              </Typography>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  color: "#FF6C86",
                  fontSize: { xs: "1rem", md: "1.2rem" },
                  // marginY: 3,
                }}
              >
                {editCartPending ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: "#FF6B6B", marginRight: "1rem" }}
                  />
                ) : (
                  <>₹ {total}</>
                )}
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
          </>
        )}
        {/* <Box px={2}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "#242424",
              fontSize: { xs: ".75rem", md: ".9rem" },
              // marginY: 3,
              textAlign: "start",
            }}
          >
            Add <span style={{ color: "#FF6C86" }}>₹{total}</span> to cart and
            get free shipping!
          </Typography>
        </Box> */}
        <Box
          width={"100%"}
          display={"flex"}
          gap={2}
          justifyContent={"center"}
          mt={{ xs: 2, md: 0 }}
          px={{ md: 2, xs: 1 }}
          py={2}
        >
          <Box width={"50%"}>
            <Button
              onClick={() => navigate(routes.cart.paths[0])}
              variant="contained"
              sx={{
                width: "100%",
                height: "100%",
                bgcolor: "#FF6C86",
                borderRadius: "25px",
                color: "#fff",
                fontSize: { xs: ".7rem", sm: ".8rem", md: ".9rem" },
                textTransform: "capitalize",
                px: { xs: 1, sm: 2, md: 3 },
                py: 1,
                boxShadow: "none",
                ":hover": {
                  bgcolor: "#FF6C86",
                },
              }}
              disabled={cartData && cartData[0]?.status === 0}
            >
              More Details
            </Button>
          </Box>
          <Box width={"50%"}>
            <Button
              onClick={() => navigate(routes.checkout.paths[0])}
              variant="contained"
              sx={{
                width: "100%",
                height: "100%",
                bgcolor: "#FF6C86",
                borderRadius: "25px",
                color: "#fff",
                fontSize: { xs: ".7rem", sm: ".8rem", md: ".9rem" },
                textTransform: "capitalize",
                px: { xs: 1, sm: 2, md: 3 },
                py: 1,
                boxShadow: "none",
                ":hover": {
                  bgcolor: "#FF6C86",
                },
              }}
              disabled={cartData && cartData[0]?.status === 0}
            >
              Checkout
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
  return (
    <Drawer
      container={container}
      variant="temporary"
      anchor={"right"}
      open={shoppingCartOpen}
      onClose={handleShoppingDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        // display: { xs: "block", lg: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: { xs: "100%", sm: 550 },
        },
      }}
    >
      {shoppingDrawer}
    </Drawer>
  );
};

export default ShoppingDrawer;
