import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import CartDelete from "../../assets/Cart/deleteIcon.svg";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createPromiseToast } from "../../utils/createPromiseToast";
import api from "../../api/api";

const DeleteProductModal = ({ open, handleClose, billno }) => {
  const queryClient = useQueryClient();

  const { mutate: deleteProductMutate, isPending: deleteProductLoading } =
    useMutation({
      mutationFn: async (data) => await api.deleteFromCart(data),
      onSuccess: (res) => {
        if (res?.data[0]?.result === 1) {
          queryClient.invalidateQueries({ queryKey: ["cart"] });
          queryClient.invalidateQueries({ queryKey: ["cartSummary"] });
          createPromiseToast({
            success: true,
            message: "Product deleted succesfully",
          });
          handleClose();
        }
      },
      onError: (error) => {
        console.error(error);
        createPromiseToast({ success: false, message: error.message });
      },
    });

  const handleDeleteFromCart = () => {
    deleteProductMutate(billno);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
      }}
    >
      <Box sx={style}>
        <Box
          my={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          gap={3}
          mx={3}
        >
          <img src={CartDelete} alt="" style={{ height: "40px" }} />
          <Typography textAlign={"center"}>
            Are you sure you want to remove this product from the cart?
          </Typography>
        </Box>
        <Box
          width={"100%"}
          display={"flex"}
          gap={2}
          justifyContent={"center"}
          mt={{ xs: 2, md: 0 }}
          px={{ md: 2, xs: 1 }}
          py={2}
        >
          <Box width={"50%"}>
            <Button
              onClick={handleClose}
              variant="contained"
              sx={{
                width: "100%",
                height: "100%",
                bgcolor: "#FF6C86",
                borderRadius: "25px",
                color: "#fff",
                fontSize: { xs: ".7rem", sm: ".8rem", md: ".9rem" },
                textTransform: "capitalize",
                px: { xs: 1, sm: 2, md: 3 },
                py: 1,
                ":hover": {
                  bgcolor: "#FF6C86",
                },
              }}
            >
              Cancel
            </Button>
          </Box>
          <Box width={"50%"}>
            <Button
              onClick={handleDeleteFromCart}
              variant="contained"
              sx={{
                width: "100%",
                height: "100%",
                bgcolor: "#fff",
                borderRadius: "25px",
                color: "#FF6C86",
                fontSize: { xs: ".7rem", sm: ".8rem", md: ".9rem" },
                textTransform: "capitalize",
                px: { xs: 1, sm: 2, md: 3 },
                py: 1,
                ":hover": {
                  bgcolor: "#fff",
                },
              }}
            >
              {deleteProductLoading ? (
                <CircularProgress size={15} sx={{ color: "#FF6C86" }} />
              ) : (
                "Delete"
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteProductModal;
const style = {
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  width: { xs: "70%", sm: "60%", md: "35%" },
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 3,
  //   height: "90%",
  overflow: "auto",
  p: 2,
};
