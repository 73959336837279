//@ts-check
import axios from "axios";
import api from "./api";

const BASE_URL = "https://shills.azurewebsites.net/api";

const securedAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    ApiToken: "",
    mobno: 1234567890,
  },
});

export const setAuthHeaders = (token, mobNo) => {
  console.log(token, mobNo);
  securedAxiosInstance.defaults.headers.ApiToken = token;
  securedAxiosInstance.defaults.headers.mobno = mobNo;
};

export const removeAuthHeaders = () => {
  securedAxiosInstance.defaults.headers.ApiToken = "";
  securedAxiosInstance.defaults.headers.mobno = 1234567890;
};

const refreshToken = async () => {
  try {
    const mobNo = await localStorage.getItem("mobNo");
    let { data } = await api.refreshToken(mobNo);
    let authToken = data[0]?.authtoken;
    setAuthHeaders(authToken, mobNo);
    localStorage.setItem("authToken", authToken);
    return authToken;
  } catch (error) {
    console.log(error);
  }
};

securedAxiosInstance.interceptors.response.use(
  async (response) => {
    let statusCode = response?.data[0]?.result;
    if (statusCode !== 2) {
      return response;
    }
    console.log("token expired");
    let authToken = await refreshToken();
    const responseConfig = { ...response.config };
    responseConfig.headers.ApiToken = authToken;
    let newData = await axios.request(response.config);
    console.log(newData?.data);
    return newData;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { securedAxiosInstance };
