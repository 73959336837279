import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import muiTheme from "../theme/theme";
import { useMediaQuery } from "@mui/material";

export default function HorizontalLinearStepper({ steps, activeStep }) {
  const isStepOptional = (step) => {
    return step === 1;
  };
  const matches = useMediaQuery(muiTheme.breakpoints.up("sm"));
  return (
    <Box sx={{ width: "100%", paddingLeft: { xs: "2rem", md: 0 } }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel={matches}
        orientation={`${matches ? "horizontal" : "vertical"}`}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label}>
              <StepLabel className="stepper-label">{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
