import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import logo from "../assets/Logo/Logo.svg";
import phoneIcon from "../assets/Footer/phone.svg";
import mailIcon from "../assets/Footer/mail.svg";
import whatsAppIcon from "../assets/Footer/whatsapp.svg";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import api from "../api/api";
import whatsAppLogo from "../assets/Footer/whatsapp.webp";
import youtubeLogo from "../assets/Footer/youtube.webp";
import instagramLogo from "../assets/Footer/instagram.webp";
import facebookLogo from "../assets/Footer/facebook.webp";

const Footer = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { data: categories, isLoading: isCategoriesLoading } = useQuery({
    queryKey: ["categories"],
    queryFn: () => api.getAllCategory().then((res) => res.data),
    staleTime: "Infinity",
    cacheTime: "Infinity",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setList(
      categories?.map((item) => {
        return {
          subTitle: item?.category,
          path: `/shop/${item.ID}/${item.SUBCATEGORY[0].SUBCATGID}`,
          icon: "",
        };
      })
    );
  }, [categories]);

  const footerData = [
    {
      title: "Categories",
      path: "",
      subTitles: list,
    },
    {
      title: "Quick Links",
      path: "",
      subTitles: [
        {
          subTitle: "Track Your Order",
          path: "/order/Pending",
          icon: "",
        },
        {
          subTitle: "About",
          path: "/about",
          icon: "",
        },
        {
          subTitle: "Shop",
          path: "/shop/187/212",
          icon: "",
        },
        // {
        //   subTitle: "Blog",
        //   path: "/blog",
        //   icon: "",
        // },
      ],
    },
    {
      title: "Help",
      path: "",
      subTitles: [
        {
          subTitle: "Terms & Conditions",
          path: "/termsandconditions",
          icon: "",
        },
        {
          subTitle: "Privacy Policy",
          path: "/privacy_policy",
          icon: "",
        },
        // {
        //   subTitle: "Delivery & Return",
        //   path: "/",
        //   icon: "",
        // },
        {
          subTitle: "Contact",
          path: "/contact",
          icon: "",
        },
      ],
    },
    {
      title: "Contact Us",
      path: "",
      subTitles: [
        // {
        //   subTitle: "9137202897",
        //   path: "tel:/+919137202897",
        //   icon: phoneIcon,
        // },
        {
          subTitle: "info@shillsprofessional.com",
          path: "mailto:info@shillsprofessional.com",
          icon: mailIcon,
        },
        // {
        //   subTitle: "9137202897",
        //   path: "/",
        //   icon: whatsAppIcon,
        // },
      ],
    },
  ];

  // console.log(footerData);

  return (
    <Box
      bgcolor={"#FFF7F8"}
      sx={{
        padding: { xs: "1rem 1rem", md: "2rem 4rem" },
      }}
    >
      <Grid container>
        <Grid
          item
          sm={12}
          md={3}
          xs={12}
          sx={{ paddingX: { xs: "0rem !important", md: "0 !important" } }}
        >
          <Box
            onClick={() => navigate("/")}
            sx={{
              display: { sm: "block" },
              // marginRight: { lg: 10, sm: 5 },
              cursor: "pointer",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box sx={{ width: "8rem", height: "4rem" }}>
                <img src={logo} alt="" width={"100%"} height={"100%"} />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          container
          px={3}
          md={9}
          sm={12}
          xs={12}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          sx={{ paddingX: { xs: "0rem", md: "0 !important" } }}
        >
          {!isCategoriesLoading &&
            footerData?.map((data, index) => {
              // if (isSmallScreen && data.title == "Contact Us") return null;
              return (
                <Grid
                  item
                  md={3}
                  sm={6}
                  xs={isSmallScreen && data.title == "Contact Us" ? 12 : 6}
                  // flex={1}
                  key={index}
                  // width={{ xs: "150px", md: "200px" }}
                  // minWidth={{ xs: "150px", md: "200px" }}
                  my={2}
                >
                  <Typography
                    variant="div"
                    sx={{
                      color: "#FF6C86",
                      fontWeight: 500,
                      fontSize: { xs: "1rem", md: "1rem" },
                      marginBottom: 1,
                    }}
                  >
                    {data?.title}
                  </Typography>
                  {data?.subTitles?.map((dt, id) => (
                    <Box sx={{ width: "100%" }}>
                      <Link
                        to={dt.path}
                        style={{ textDecoration: "none", width: "100%" }}
                      >
                        <Typography
                          key={id}
                          my={{ xs: 1, md: ".8rem" }}
                          sx={{
                            color: "#2D2C2C",
                            cursor: "pointer",
                            fontWeight: 400,
                            fontSize: { xs: ".8rem", md: ".8rem" },
                            display: "flex",
                            alignItems: "center",
                            gap: 1.5,
                            textOverflow: "ellipsis",
                            // overflow: "hidden",
                            width: "100%",
                            wordWrap: "break-word",
                            "&:hover": {
                              // color: "#EE7F1C",
                            },
                          }}
                        >
                          {dt.icon !== "" ? (
                            <Box
                              display={"inline-block"}
                              height={22}
                              paddingY={id === 1 ? "2px" : null}
                            >
                              <img
                                src={dt.icon}
                                alt=""
                                srcset=""
                                height={"100%"}
                              />
                            </Box>
                          ) : (
                            ""
                          )}{" "}
                          {dt.subTitle}
                        </Typography>
                      </Link>
                    </Box>
                  ))}
                  {data.title === "Quick Links" && (
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSeJKR0XjGZ0KNv1OdZG3eOMQoO8lqyCjyLD207BXuZa1QADMg/viewform?vc=0&c=0&w=1&flr=0"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#2D2C2C",
                          cursor: "pointer",
                          fontWeight: 400,
                          fontSize: { xs: ".8rem", md: ".8rem" },
                          display: "flex",
                          alignItems: "center",
                          gap: 1.5,
                          width: "fit-content",
                          "&:hover": {
                            // color: "#EE7F1C",
                          },
                        }}
                      >
                        Affiliate With Us
                      </Typography>
                    </a>
                  )}
                  {data.title == "Contact Us" && (
                    <Stack direction={"row"} gap={".5rem"}>
                      <Box
                        sx={{
                          width: "2.1rem",
                          height: "2rem",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        <Link to="https://www.instagram.com/shills_professional?igsh=MXgweGF4azNlOWVocg%3D%3D&utm_source=qr">
                          <img
                            src={instagramLogo}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Link>
                      </Box>
                      <Box
                        sx={{
                          width: "2rem",
                          height: "2rem",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        <Link to="https://youtube.com/@shillsprofessional?si=UgxdE5HK-T7UXc_5">
                          <img
                            src={youtubeLogo}
                            style={{ width: "100%", height: "90%" }}
                          />
                        </Link>
                      </Box>
                      <Box
                        sx={{
                          width: "2rem",
                          height: "2rem",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        <Link to="https://www.facebook.com/share/kECxgsmwpMzQNUhQ/">
                          <img
                            src={facebookLogo}
                            style={{ width: "100%", height: "90%" }}
                          />
                        </Link>
                      </Box>
                      {/* <Box
                        sx={{
                          width: "2rem",
                          height: "2rem",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        <Link>
                          <img
                            src={whatsAppLogo}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </Link>
                      </Box> */}
                    </Stack>
                  )}
                </Grid>
              );
            })}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        padding={{ xs: "0rem 0rem", lg: "0 0rem" }}
        sx={{ mt: { xs: "1.2rem", md: "0rem" } }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: { xs: "flex-start", md: "center" },
            gap: { xs: "1rem", md: "0" },
          }}
        >
          <Typography
            sx={{
              color: "#2D2C2C",
              fontWeight: "500",
              display: "flex",
              fontSize: { xs: ".8rem", md: "1rem" },
            }}
          >
            2024 Shillsprofessional. @All rights reserved Powered by Manzad
          </Typography>

          <Typography
            sx={{
              color: "#2D2C2C",
              fontWeight: "500",
              display: "flex",
              fontSize: { xs: ".8rem", md: "1rem" },
            }}
          >
            Designed & Developed by{" "}
            <Link
              to="https://dwebbox.com/"
              style={{
                color: "#FF6C86", // "rgb(238, 127, 28)"
                textDecoration: "none",
                fontWeight: "500",
                marginLeft: ".3rem",
              }}
              target="_blank"
            >
              {" "}
              D-WebBox
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
