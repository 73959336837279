import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  productCode: null,
  sale_rate: null,
};

const productModalSlice = createSlice({
  name: "productModalState",
  initialState,
  reducers: {
    toggleModal: (state, action) => {
      state.isOpen = !state.isOpen;
    },
    setProductCode: (state, action) => {
      state.productCode = action.payload;
      state.isOpen = true;
    },
    setProductSaleRate: (state, action) => {
      state.sale_rate = action.payload;
    },
    clearModalState: (state, action) => {
      state.productCode = null;
      state.sale_rate = null;
    },
  },
});

export const {
  toggleModal,
  setProductCode,
  setProductSaleRate,
  clearModalState,
} = productModalSlice.actions;

export default productModalSlice;
