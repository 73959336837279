import React, { lazy } from "react";
import FallbackLoader from "../components/FallbackLoader";
import OrderDetails from "./Profile/OrderDetails";
import PaymentWebView from "./Payment/PaymentWebView";

const HomeRoutes = lazy(() => import("./Home/Home"));
const HOtDealsRoutes = lazy(() => import("./HotDeals/HotDeals"));
const AboutRoute = lazy(() => import("./About/About"));
const CartRoute = lazy(() => import("./Cart/Cart"));
const ProfileRoute = lazy(() => import("./Profile/Profile"));
const EditDetailsRoute = lazy(() => import("./EditDetails/EditDetails"));
const CheckoutRoute = lazy(() => import("./Checkout/Checkout"));
const SearchRoute = lazy(() => import("./Search/Search"));
const ShopRoute = lazy(() => import("./Shop/Shop"));
const ProductDetailsRoute = lazy(() =>
  import("./ProductDetails/ProductDetails")
);
const ViewMoreReviews = lazy(() => import("./ProductDetails/ViewMoreReviews"));
const WishListRoute = lazy(() => import("./WishList/WishList"));
const LoginRoute = lazy(() => import("./Login/Login"));
const ContactUsRoute = lazy(() => import("./ContactUs/ContactUs"));
const RegisterRoute = lazy(() => import("./Register/Register"));
const CompareRoute = lazy(() => import("./Compare/Compare"));
const BlogRoute = lazy(() => import("./Blog/Blog"));

const DashboardRoutes = lazy(() => import("./Profile/Dashboard"));
const OrderRoutes = lazy(() => import("./Profile/Orders"));
const AccountRoutes = lazy(() => import("./Profile/AccountDetails"));
const AddressRoutes = lazy(() => import("./Profile/Addresses"));
const RedirectPayment = lazy(() => import("./Payment/Redirect"));
const ViewAllProducts = lazy(() => import("./ViewAll/ViewAllProducts"));
const TermsConditions = lazy(() =>
  import("./TermsAndConditions/TermsConditions")
);
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy/PrivacyPolicy"));

export const UnProtectedRoute = {
  home: {
    key: "home",
    paths: ["/", "/home"],
    element: <HomeRoutes />,
    protected: true,
    docTitle: "Home Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  hotDeals: {
    key: "hotDeals",
    paths: ["/hot-deals"],
    element: <HOtDealsRoutes />,
    protected: true,
    docTitle: "Hot Deals Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  viewAll: {
    key: "viewAll",
    paths: ["/view-all/:type"],
    element: <ViewAllProducts />,
    docTitle: "View All",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  about: {
    key: "about",
    paths: ["/about"],
    element: <AboutRoute />,
    protected: true,
    docTitle: "About Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  contactUs: {
    key: "contactUs",
    paths: ["/contact"],
    element: <ContactUsRoute />,
    protected: true,
    docTitle: "Contact Us Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  shop: {
    key: "shop",
    paths: ["/shop", "/shop/:catId/:subCatId"],
    element: <ShopRoute />,
    protected: true,
    docTitle: "Shop Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  search: {
    key: "search",
    paths: ["/search"],
    element: <SearchRoute />,
    protected: true,
    docTitle: "Search Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  productDetails: {
    key: "productDetails",
    paths: ["/productDetails", "/productDetails/:id/:subCatId"],
    element: <ProductDetailsRoute />,
    protected: true,
    docTitle: "Product Details Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  viewMoreReviews: {
    key: "viewMoreDetails",
    paths: ["/productDetails/:id/:subCatId/more-reviews"],
    element: <ViewMoreReviews />,
    protected: true,
    docTitle: "View More Reviews",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  wishList: {
    key: "wishList",
    paths: ["/wishList"],
    element: <WishListRoute />,
    protected: true,
    docTitle: "WishList Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  compare: {
    key: "compare",
    paths: ["/compare"],
    element: <CompareRoute />,
    protected: true,
    docTitle: "Comoare Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  blog: {
    key: "blog",
    paths: ["/blog"],
    element: <BlogRoute />,
    protected: true,
    docTitle: "Blog Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  login: {
    key: "login",
    paths: ["/login"],
    element: <LoginRoute />,
    protected: false,
    docTitle: "Login Page",
    suspense: true,
    fallback: <FallbackLoader />,
    // component: </>
  },
  register: {
    key: "register",
    paths: ["/register"],
    element: <RegisterRoute />,
    protected: false,
    docTitle: "Register Page",
    suspense: true,
    fallback: <FallbackLoader />,
    // component: </>
  },
  payment: {
    key: "payment",
    paths: ["/payment/redirect/:pgId"],
    element: <RedirectPayment />,
    protected: true,
    docTitle: "Payment Redirect Page",
    suspense: true,
    fallback: <FallbackLoader />,
    // component: </>
  },
  terms: {
    key: "terms",
    paths: ["termsandconditions"],
    element: <TermsConditions />,
    protected: false,
    docTitle: "Terms And Conditions",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  privacyPolicy: {
    key: "privacy_policy",
    paths: ["/privacy_policy"],
    element: <PrivacyPolicy />,
    protected: false,
    docTitle: "Privacy Policy",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  paymentWebView: {
    key: "payment_web_view",
    paths: ["/payment_web_view/:pgUrl"],
    element: <PaymentWebView />,
    protected: false,
    docTitle: "Payment Web View",
    suspense: true,
    fallback: <FallbackLoader />,
  },
};
export const UnProtectedRouteList = Object.entries(UnProtectedRoute);

export const ProtectedRoute = {
  cart: {
    key: "cart",
    paths: ["/cart"],
    element: <CartRoute />,
    protected: true,
    docTitle: "Cart Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },

  checkout: {
    key: "checkout",
    paths: ["/checkout"],
    element: <CheckoutRoute />,
    protected: true,
    docTitle: "Checkout Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  profile: {
    key: "profile",
    paths: ["/profile"],
    element: <ProfileRoute />,
    protected: true,
    docTitle: "Profile Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  editDetails: {
    key: "editDetails",
    paths: ["/editDetails"],
    element: <EditDetailsRoute />,
    protected: true,
    docTitle: "Edit Details Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  dashboard: {
    key: "dashboard",
    paths: ["/profile/dashboard"],
    element: <DashboardRoutes />,
    protected: true,
    docTitle: "Dashboard Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  order: {
    key: "order",
    paths: ["/order/:orderType"],
    element: <OrderRoutes />,
    protected: true,
    docTitle: "Dashboard Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  orderDetails: {
    key: "orderDetails",
    paths: ["/order/:orderType/:orderId"],
    element: <OrderDetails />,
    protected: true,
    docTitle: "Dashboard Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  download: {
    key: "downloads",
    paths: ["/profile/download"],
    element: <DashboardRoutes />,
    protected: true,
    docTitle: "Dashboard Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  address: {
    key: "addresses",
    paths: ["/profile/address"],
    element: <AddressRoutes />,
    protected: true,
    docTitle: "Dashboard Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
  account: {
    key: "account details",
    paths: ["/profile/account"],
    element: <AccountRoutes />,
    protected: true,
    docTitle: "Dashboard Page",
    suspense: true,
    fallback: <FallbackLoader />,
  },
};

export const ProtectedRouteList = Object.entries(ProtectedRoute);
const routes = {
  ...ProtectedRoute,
  ...UnProtectedRoute,
};
export const routesList = [...ProtectedRouteList, ...UnProtectedRouteList];

export default routes;
