import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const PaymentWebView = () => {
  const params = useParams();

  useEffect(() => {
    console.log(decodeURIComponent(params.pgUrl), "decoded url");
    window.location.href = decodeURIComponent(params.pgUrl);
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#FFFF",
        top: 0,
        zIndex: 10,
        display: "grid",
        placeItems: "center",
      }}
    >
      <CircularProgress size={100} />
    </Box>
  );
};

export default PaymentWebView;
