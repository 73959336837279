import { securedAxiosInstance } from "./instance";

const api = {};

// M A S T E R
api.country = () => securedAxiosInstance.get("/Master/Country");

api.state = (data) => securedAxiosInstance.get(`/Master/State?country=${data}`);

api.city = (data) => securedAxiosInstance.get(`/Master/City?state=${data}`);

// A U T H
api.register = (data) => securedAxiosInstance.post("/Register", data);

api.login = (data) => securedAxiosInstance.post(`/auth/generateotp`, data);

api.refreshToken = (data) =>
  securedAxiosInstance.get(`/RefreshToken?mobno=${data}`);

api.verifyOtp = (data) => securedAxiosInstance.post(`/auth/verifyOtp`, data);

api.resendOtp = (data) => securedAxiosInstance.post(`/auth/generateotp`, data);

api.refreshToken = (mobno) =>
  securedAxiosInstance.get(`/RefreshToken?mobno=${mobno}`);

// H O M E

api.viewAllProducts = (
  type,
  page,
  rows,
  sortBy,
  priceLow,
  priceUpper,
  catgid,
  ordType
) =>
  securedAxiosInstance.get(
    `/Home/${type}?ordType=${ordType}&page=${page}&rows=${rows}&sortby=${
      type == "LastView" && sortBy == "prd_code" ? "LASTVIEWDATE DESC" : sortBy
    }&catgid=${catgid}&pricelow=${priceLow}&priceupper=${priceUpper}`
  );
api.newArrival = () =>
  securedAxiosInstance.get("/Home/NewArrival?ordType=1&page=1&rows=10");

api.lastViewed = () =>
  securedAxiosInstance.get("/Home/LastView?ordType=2&page=1&rows=10");

api.bestDeals = () =>
  securedAxiosInstance.get("/Home/BestDeals?ordType=3&page=1&rows=10");

// P R O F I L E

api.profile = () => securedAxiosInstance.get("/User/GetProfile");

api.updateProfile = (data) =>
  securedAxiosInstance.post("/UpdateUser/UpdatePersonal", data);

api.uploadProfileImage = (file) =>
  securedAxiosInstance.post("/UpdateUser/UploadProfileImg", file);

api.getImage = (imageLink) => securedAxiosInstance.get(imageLink);

api.getHomeBanner1 = () => securedAxiosInstance.get("/Home/Banner/1");
api.getHomeBanner2 = () => securedAxiosInstance.get("/Home/Banner/2");
api.getHomeBanner3 = () => securedAxiosInstance.get("/Home/Banner/3");

// A D D R E S S

api.getAddressList = () => securedAxiosInstance.get("/Address");

api.addAddress = (data) =>
  securedAxiosInstance.post("/Address", {
    ...data,
    mobno: data.mobno.toString(),
  });

api.updateAddress = (id, data) =>
  securedAxiosInstance.put(`/Address/${id}`, {
    ...data,
    mobno: data.mobno.toString(),
  });

api.deleteAddress = (id) => securedAxiosInstance.delete(`/Address/${id}`);

// C A T E G O R I E S

api.getCategories = () => securedAxiosInstance.get("/Category");

api.getAllCategory = () => securedAxiosInstance.get("/AllCategory");

api.getAllCategoryAndSubcategory = () =>
  securedAxiosInstance.get("/Allcategory");

api.getAllSubCat = () => {
  securedAxiosInstance.get("/AllSubCatg");
};

api.getSubcats = (catId) => securedAxiosInstance.get(`/Category/${catId}`);

api.getNewArrivals = () => securedAxiosInstance.get("/newArrival");

// PRODUCTS

// api.getAllProducts = (prdCode) => securedAxiosInstance.get(`/Products/${prdCode}?sortby=prd_code&page=1&rows=50`);

api.getAllProducts = (
  selectedId,
  page,
  rows,
  prd_code,
  searchText = "",
  priceLow,
  priceUpper
) =>
  securedAxiosInstance.get(
    `/Products/${selectedId}?sortby=${prd_code}&page=${page}&rows=${rows}&pricelow=${priceLow}&priceupper=${priceUpper}&searchtext=${searchText}`
  );

api.getProductsDetails = (prdCode) =>
  securedAxiosInstance.get(`/Products/Detail/${prdCode}`);

api.getAllRecommendedProducts = (subCatId, prd_code) =>
  securedAxiosInstance.get(
    `/Products/Recommend/${subCatId}/${prd_code}?sortby=prd_code&page=1&rows=10`
  );
api.getProductVariants = (prod_code, page, rows) =>
  securedAxiosInstance.get(
    `/Products/Detail/Variant/${prod_code}?page=${page}&rows=${rows}`
  );
// C A R T

api.addToCart = (data) => securedAxiosInstance.post("/Cart/", data);

api.getCart = () => securedAxiosInstance.get("/Cart");

api.getCartSummary = () => securedAxiosInstance.get("/Cart/Summary");

api.deleteFromCart = (billno) => securedAxiosInstance.delete(`/Cart/${billno}`);

api.editCart = (billno, data) =>
  securedAxiosInstance.put(`/Cart/${billno}`, data);

api.clearCart = () => securedAxiosInstance.delete("/Cart/");

api.checkout = (data) => securedAxiosInstance.post("/Cart/Checkout", data);

// FAVOURITES

api.favouriteProduct = (isFav, prdCode) =>
  securedAxiosInstance.post(`/Favourite?fav=${isFav}&prd_code=${prdCode}`);

api.getFavouriteProduct = () => securedAxiosInstance.get("/Favourite");

// C O U P A N
api.checkCoupan = (data) => securedAxiosInstance.get(`/Coupan/${data}`);

// ORDERS

api.getOrders = (orderType) => securedAxiosInstance.get(`/Order/${orderType}`);

api.getParticularOrder = (orderType, billno) =>
  securedAxiosInstance.get(`/Order/${orderType}/${billno}`);

// product reviews and ratings

api.getAllProductReviews = (product_code) =>
  securedAxiosInstance.get(`/Review/${product_code}/1`);

api.submitReview = (data) => securedAxiosInstance.post("/Review", data);

api.getAllProductRatings = (product_code) =>
  securedAxiosInstance.get(`/Review/${product_code}/0`);

api.checkStatus = (orderno) =>
  securedAxiosInstance.get(`/Payment/ChkStatus/${orderno}`);

//  Offers

api.getOffersCategory = (page, rows, sortBy, priceLow, priceHigh) =>
  securedAxiosInstance.get(
    `/Offers/Category?page=${page}&rows=${rows}&sortyby=${sortBy}&pricelow=&priceupper=`
  );

api.getOffersOnSubCategory = (page, rows, sortBy, priceLow, priceHigh) =>
  securedAxiosInstance.get(
    `/Offers/SubCategory?page=${page}&rows=${rows}&sortyby=${sortBy}&pricelow=${priceLow}&priceupper=${priceHigh}`
  );

api.getOffersOnProducts = (page, rows, sortBy, priceLow, priceHigh) =>
  securedAxiosInstance.get(
    `/Offers/Products?page=${page}&rows=${rows}&sortyby=${sortBy}&pricelow=${priceLow}&priceupper=${priceHigh}`
  );
export default api;
