import { createSlice } from "@reduxjs/toolkit";
import { removeAuthHeaders, setAuthHeaders } from "../api/instance";

const initialState = {
  isAuthenticated: localStorage.getItem("isAuthenticated") ? true : false,
  userDetails: null,
};

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("mobNo", action.payload.mobNo);
      localStorage.setItem("authToken", action.payload.authToken);

      state.isAuthenticated = true;
    },
    logOut: (state) => {
      removeAuthHeaders();
      state.isAuthenticated = false;
      localStorage.clear();
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      localStorage.setItem("userDetails", JSON.stringify(action.payload));
    },
  },
});

export const { login, logOut, setUserDetails } = slice.actions;

export default slice.reducer;
