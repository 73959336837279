import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const OrderProductList = ({ orderProduct }) => {
  return (
    <Grid
      key={Math.random()}
      container
      width={"100%"}
      sx={{
        my: {
          xs: ".5rem",
          md: ".8rem",
          lg: "1rem",
        },
        px: {
          xs: ".5rem",
          md: ".8rem",
          lg: "1rem",
        },
        paddingBottom: { xs: ".5rem", md: ".8rem" },
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 4px 12px",
      }}
    >
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          alignItems: { xs: "flex-start", md: "center" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <LazyLoadImage src={orderProduct?.IMAGE} effect="blur" height={100} />
        <Box>
          <Typography
            variant="p"
            component="div"
            sx={{
              color: "#858D97",
              fontWeight: 400,
              marginX: 2,
              fontSize: { xs: ".8rem", md: ".9rem" },
            }}
          >
            Product ID: {orderProduct?.PRD_CODE}
          </Typography>
          <Typography
            variant="p"
            component="div"
            sx={{
              color: "#2D2C2C",
              fontWeight: 500,
              marginX: 2,
              fontSize: { xs: ".9rem", md: "1.1rem" },
            }}
          >
            {orderProduct?.PRD_NAME}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            placeItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", placeItems: "center", pl: 1 }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: ".75rem", md: ".9rem" },
                fontWeight: "400",
                color: "#2D2C2C",
              }}
            >
              {orderProduct?.QUANTITY}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="subtitle1"
          component="div"
          sx={{
            color: "#2D2C2C",
            fontWeight: 500,
            // marginY: 3,
            fontSize: { xs: ".9rem", md: "1rem" },
          }}
        >
          ₹ {orderProduct?.AMOUNT}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OrderProductList;
