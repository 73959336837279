import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import SelectVarientModal from "./Modal/SelectVarientModal";

const OutletComponent = () => {
  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer />
      <SelectVarientModal />
    </div>
  );
};

export default OutletComponent;
