import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth.slice";
import categoryReducer from "./Categories.slice";
import cartReducer from "./cart.slice";
import productModalSlice from "./productModal.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    categories: categoryReducer,
    cart: cartReducer,
    productModalState: productModalSlice.reducer,
  },
});
