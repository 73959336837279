import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useMemo, useLayoutEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import api from "../../api/api";
import productDummyImage from "../../assets/Product/productImgBig.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import HorizontalLinearStepper from "../../components/HorizontalLinearStepper";
import CircularProgress from "@mui/material/CircularProgress";
import OrderProductList from "../../components/OrderProductList";
import { createPromiseToast } from "../../utils/createPromiseToast";

const steps = [
  "Order Placed",
  // "Payment Pending",
  "Approved",
  "Sent for Packing",
  "Partially Dispatched",
  "Dispatched",
  "Delivered",
];

const OrderDetails = () => {
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const { state } = useLocation();

  const [activeStep, setActiveStep] = useState(1);

  const getData = async () => {
    try {
      const payDetails = await api.checkStatus(state?.billNo);
      const response = JSON.parse(payDetails?.data[0]?.message)?.message;
      switch (JSON.parse(response)?.data?.state) {
        case "PENDING":
          createPromiseToast({
            success: true,
            message: "Payment still pending",
          });
          break;
        case "COMPLETED":
          createPromiseToast({ success: true, message: "Payment successful" });
          break;
        case "FAILED":
          createPromiseToast({
            success: false,
            message: "Payment failed",
          });
          break;
        default:
          console.log("entered into default");
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { orderType, orderId } = useParams();

  const navigate = useNavigate();

  const { data: orderDetailsData, isLoading: isProductDetailsLoading } =
    useQuery({
      queryKey: ["orderDetails", orderId],
      queryFn: () => api.getParticularOrder(orderType, orderId),
      staleTime: " Infinity",
      cacheTime: " Infinity",
      refetchOnMount: false,
      refetchOnWindowFocus: true,
    });

  // const orderStepperStep = orderStatus.split(" - ")[0];
  // const orderStepperStepDate = orderStatus.split(" - ")[1];

  const total = useMemo(
    () =>
      orderDetailsData?.data?.reduce((acc, item) => {
        if (item.PRD_CODE === "COUPANDISC") {
          return acc - Math.abs(parseInt(item.AMOUNT));
        } else {
          return acc + parseInt(item.AMOUNT);
        }
      }, 0),
    [orderDetailsData]
  );
  // AWB_CODE
  const subTotal = useMemo(
    () =>
      orderDetailsData?.data?.reduce((acc, item) => {
        if (item.PRD_CODE === "COUPANDISC" || item.PRD_CODE == "SHIP") {
          return acc;
        } else {
          return acc + parseInt(item.AMOUNT);
        }
      }, 0),
    [orderDetailsData]
  );

  const shippingCharges = useMemo(() => {
    return (
      orderDetailsData?.data?.find((item) => item.PRD_CODE === "SHIP")
        ?.AMOUNT || 0
    );
  }, [orderDetailsData]);

  const AWB_CODE = useMemo(() => {
    return orderDetailsData?.data?.find((item) => item.PRD_CODE === "SHIP")
      ?.AWB_CODE;
  }, [orderDetailsData]);

  const discountAmount = useMemo(() => {
    return Math.abs(
      orderDetailsData?.data?.find((item) => item.PRD_CODE === "COUPANDISC")
        ?.AMOUNT || 0
    );
  }, [orderDetailsData]);

  const noOfProducts = useMemo(
    () =>
      orderDetailsData?.data?.reduce((acc, item) => {
        if (item.PRD_CODE === "COUPANDISC" || item.PRD_CODE === "SHIP") {
          return acc;
        } else {
          return acc + 1;
        }
      }, 0),
    [orderDetailsData]
  );

  useEffect(() => {
    if (orderDetailsData?.data[0]) {
      setOrderDetails(orderDetailsData?.data);
      setOrderStatus(orderDetailsData.data[0]?.ORDHISTSTATUS);
      console.log(orderDetailsData?.data[0]?.ORDERTRACK);
      switch (orderDetailsData?.data[0]?.ORDERTRACK) {
        case "":
          setActiveStep(0);
          break;
        case "Payment Pending":
          setActiveStep(1);
          break;
        case "Approved":
          setActiveStep(2);
          break;
        case "Sent for Packing":
          setActiveStep(3);
          break;
        case "Partially Dispatched":
          setActiveStep(4);
          break;
        case "Dispatched":
          setActiveStep(5);
          break;
        case "Delivered":
          setActiveStep(6);
          break;
        default:
          setActiveStep(0);
          break;
      }
    }
  }, [orderDetailsData]);

  // useEffect(() => {
  //   // if (orderStepperStep === "Pending") {
  //   setActiveStep(0);
  //   // }
  // }, []);

  useLayoutEffect(() => {
    if (state?.shouldCheckPayment) {
      getData();
    }
  }, []);

  return (
    <Box>
      {isProductDetailsLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "40vh",
          }}
        >
          <CircularProgress sx={{ color: "#FF6C86" }} size={45} />
        </Box>
      ) : (
        <Grid
          container
          spacing={{ lg: "4rem", md: "2rem" }}
          px={{ lg: "4rem", md: "3rem", xs: ".5rem", sm: "1.5rem" }}
          py={{ lg: "3rem", md: "2rem", xs: ".5rem", sm: "1rem" }}
        >
          {/* left */}
          <Grid item lg={6} xs={12} sm={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { sx: ".5rem", md: ".6rem" },
                  marginTop: { xs: "1.5rem", md: 0 },
                  paddingX: { xs: ".8rem", md: 0 },
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: "1rem", md: "1.2rem", lg: "1.4rem" },
                  }}
                >
                  Order ID : {orderDetails[0]?.BILLNO}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "0.8rem", md: "1rem" },
                  }}
                >
                  Order Date : {orderDetails[0]?.ORDDATE}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "0.8rem", md: "1rem" },
                  }}
                >
                  Number of Products : {noOfProducts}
                </Typography>

                {AWB_CODE !== "" && (
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: "0.8rem", md: "1rem" },
                    }}
                  >
                    AWB CODE : {AWB_CODE}
                  </Typography>
                )}
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { xs: "0.8rem", md: "1rem" },
                  }}
                >
                  Payment Status :
                  {orderDetails[0]?.PAYSTATUS === "PAYMENT_SUCCESS"
                    ? " Completed"
                    : null}
                  {orderDetails[0]?.PAYSTATUS === "PAYMENT_FAILED"
                    ? " Failed"
                    : null}
                  {orderDetails[0]?.PAYSTATUS === "PAYMENT_ERROR"
                    ? " Failed"
                    : null}
                  {orderDetails[0]?.PAYSTATUS === "PAYMENT_PENDING" ||
                  orderDetails[0]?.PAYSTATUS === ""
                    ? orderDetails[0]?.PAYMODE === "COD"
                      ? " Cash on delivery "
                      : " Pending"
                    : null}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: "1rem", md: "1.2rem", lg: "1.4rem" },
                    my: { md: "1rem", xs: "0.5rem" },
                    paddingX: { xs: ".8rem", md: 0 },
                  }}
                >
                  Product List
                </Typography>

                <Grid
                  container
                  width={"100%"}
                  sx={{
                    my: {
                      xs: ".5rem",
                      md: ".8rem",
                      lg: "1rem",
                    },
                    px: {
                      xs: ".5rem",
                      md: ".8rem",
                      lg: "1rem",
                    },
                  }}
                >
                  <Grid item xs={6} md={6} lg={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#2D2C2C",
                        textAlign: "left",
                        pl: "1rem",
                        fontSize: { xs: ".8rem", md: "1rem" },
                      }}
                    >
                      Products
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#2D2C2C",
                        textAlign: "center",
                        fontSize: { xs: ".8rem", md: "1rem" },
                      }}
                    >
                      Quantity
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#2D2C2C",
                        textAlign: "center",
                        fontSize: { xs: ".8rem", md: "1rem" },
                      }}
                    >
                      Total
                    </Typography>
                  </Grid>
                </Grid>
                <hr
                  style={{
                    margin: ".4rem 0",
                    color: "#CDD8E3",
                    width: "95%",
                    padding: "0 1rem",
                  }}
                />
                {/* items map */}
                {orderDetails?.map((orderProduct, index) =>
                  orderProduct.PRD_CODE === "COUPANDISC" ||
                  orderProduct.PRD_CODE === "SHIP" ? null : (
                    <OrderProductList key={index} orderProduct={orderProduct} />
                  )
                )}
              </Box>
            </Box>
          </Grid>
          {/* right */}
          <Grid
            item
            lg={6}
            xs={12}
            sm={12}
            // boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
            borderRadius={2}
            paddingX={{ lg: "3rem", md: "1.2rem", sm: ".5rem" }}
            paddingY={{ lg: "1.2rem", md: "1rem", sm: ".5rem" }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              height={"100%"}
            >
              <Box>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: "#2D2C2C",
                    fontWeight: 500,
                    marginBottom: 3,
                    fontSize: { xs: "1rem", md: "1.2rem" },
                    paddingX: { xs: ".8rem", md: 0 },
                    paddingTop: { xs: ".8rem", md: 0 },
                  }}
                >
                  Price Details
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  my={2}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: "0.8rem", md: "1rem" },
                      color: "#242424",
                      paddingX: { xs: ".8rem", md: 0 },
                    }}
                  >
                    Products:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: "0.8rem", md: "1rem" },
                      color: "#2D2C2C",
                      paddingX: { xs: ".8rem", md: 0 },
                    }}
                  >
                    {noOfProducts}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingX: { xs: ".8rem", md: 0 },
                  }}
                  my={2}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: "0.8rem", md: "1rem" },
                      color: "#242424",
                    }}
                  >
                    Subtotal:
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: "0.8rem", md: "1rem" },
                      color: "#2D2C2C",
                    }}
                  >
                    {subTotal}
                  </Typography>
                </Box>
                {discountAmount ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingX: { xs: ".8rem", md: 0 },
                    }}
                    mt={2}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: { xs: "0.8rem", md: "1rem" },
                        color: "#242424",
                      }}
                    >
                      Discount :
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: { xs: "0.8rem", md: "1rem" },
                        color: "#6FCF97",
                      }}
                    >
                      {discountAmount}
                    </Typography>
                  </Box>
                ) : null}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingX: { xs: ".8rem", md: 0 },
                  }}
                  mt={2}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: "0.8rem", md: "1rem" },
                      color: "#242424",
                    }}
                  >
                    Shipping :
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: "0.8rem", md: "1rem" },
                      color: "#2D2C2C",
                    }}
                  >
                    {shippingCharges}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingX: { xs: ".8rem", md: 0 },
                  }}
                  mt={2}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: "0.8rem", md: "1rem" },
                      color: "#242424",
                    }}
                  >
                    Total :
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: { xs: "0.8rem", md: "1rem" },
                        color: "#2D2C2C",
                      }}
                    >
                      ₹ {total}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/*  */}
              <Box sx={{ marginY: 1 }}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: "#2D2C2C",
                    fontWeight: 500,
                    marginBottom: 3,
                    fontSize: { xs: "1rem", md: "1.2rem" },
                    marginY: { xs: ".5rem", md: "1rem" },
                    paddingX: { xs: ".8rem", md: 0 },
                  }}
                >
                  Billing Details
                </Typography>

                <Box
                  sx={{
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 4px 12px",
                    paddingX: { xs: ".5rem", md: "1rem", lg: "1.2rem" },
                    paddingY: { xs: ".8rem", md: "1.2rem", lg: "1.4rem" },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: "0.8rem", md: "1rem" },
                      color: "#2D2C2C",
                      textTransform: "capitalize",
                    }}
                  >
                    {orderDetails[0]?.USERNAME}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: ".8rem", md: "1rem" },
                      color: "#242424",
                      fontWeight: "400",
                      marginY: { xs: ".2rem", md: ".3rem" },
                    }}
                  >
                    {orderDetails[0]?.MOBNO}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: { xs: ".8rem", md: "1rem" },
                      color: "#242424",
                      fontWeight: "400",
                      marginY: { xs: ".2rem", md: ".3rem" },
                      textTransform: "capitalize",
                    }}
                  >
                    {orderDetails[0]?.DISP_ADDR}, {orderDetails[0]?.DISP_AREA},{" "}
                    {orderDetails[0]?.DISP_CITY}, {orderDetails[0]?.DISP_STATE},{" "}
                    {orderDetails[0]?.DISP_COUNTRY}, {orderDetails[0]?.DISP_PIN}
                    .
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    color: "#2D2C2C",
                    fontWeight: 500,
                    fontSize: { xs: "1rem", md: "1.2rem" },
                    marginTop: 3,
                    marginBottom: 2,
                    paddingX: { xs: ".8rem", md: 0 },
                  }}
                >
                  Shipping
                </Typography>
              </Box>
              <Box sx={{ width: "100%", marginTop: 3, marginBottom: 2 }}>
                <HorizontalLinearStepper
                  activeStep={activeStep}
                  steps={steps}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default OrderDetails;
